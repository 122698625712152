import React, { Component } from 'react';
import SideNav from '../Components/Sidenav/Sidenav'
import NavBar from '../Components/NavBar/NavBar'
import ResponsiveNavBar from '../Components/ResponsiveNavBar/ResponsiveNavBar'
import EmptyPage from '../Components/EmptyPage/EmptyPage'
import ReactTooltip from 'react-tooltip';
import CampaignBar from '../Components/CampaignBar/CampaignBar'
import Footer from '../Components/Footer/Footer'
import Paginator from '../Components/Paginator/Paginator'
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import DatePicker from "react-datepicker";
import { TimeScale } from 'chart.js';

const API_URL = process.env.REACT_APP_API_URL
const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN
const options = [
  { value: 'All', label: 'Todas' },
  // { value: 'Zendesk', label: 'Formulario Zendesk' },
  { value: 'Risk', label: 'Riesgo - Prevencion de fraudes' },
  { value: 'WebService', label: 'Envío manual' },
  { value: 'Cloud', label: 'Formulario Cloud' }
];
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      campaigns:[],
      filtered_campaigns:[],
      webservice_campaigns:[],
      zendesk_campaigns:[],
      paginated_campaigns:[],
      risk_campaigns:[],
      cloud_campaigns: [],
      phone_array:'',
      name_filter:'',
      user_filter:'',
      date_filter_start:'',
      date_filter_end:'',
      date_range:'',
      platform_filter:'WebService',
      users:[],
      current_page:0,
      page_size:8,
      zendesk_data: false,
      loading: true,
    }
  }
  setpage = (page) => {
    this.setState({current_page:page})
  }

  clear = () => {
    this.setState({name_filter:"",user_filter:"",date_filter_start:"", date_filter_end:"",date_range:"", platform_filter:"WebService", filtered_campaigns:this.state.webservice_campaigns})
    this.paginate(this.state.webservice_campaigns)
  }

  paginate = (campaigns) => {
    let page_size = this.state.page_size
    let page_numer = -1
    let paginated_campaigns = []
    for (let index = 0; index < campaigns.length; index++) {
      if(index%page_size == 0){
        page_numer = page_numer + 1
        paginated_campaigns.push([])
      }
      paginated_campaigns[page_numer].push(campaigns[index])
    }
    this.setState({paginated_campaigns, current_page:0})
  }

  calculate_filter = (name_filter, user_filter, date, platform) => {
    let dates = [];
    let users_by_platform = [];

    if(name_filter=="" && user_filter=="" && date=="" && platform=="All" ){

      for (let index = 0; index < this.state.campaigns.length; index++) {
        if(users_by_platform.indexOf(this.state.campaigns[index].user) == -1 ){
          users_by_platform.push(this.state.campaigns[index].user)
        }       
        dates.push(new Date(this.state.campaigns[index].created))
      }

      this.setState({filtered_campaigns:this.state.campaigns, users:users_by_platform, dates })
      this.paginate(this.state.campaigns)
      return this.state.campaigns
    }

    let users_by_zendesk = [];
    let dates_by_zendesk = [];
    
    let users_by_cloud = [];
    let dates_by_cloud = [];

    let users_by_web = [];
    let dates_by_web = [];

    if( platform != "All" )
    {
      for (let index = 0; index < this.state.campaigns.length; index++) {
        if( this.state.campaigns[index].requestedBy == "Zendesk")
        {
          
          if(users_by_zendesk.indexOf(this.state.campaigns[index].user) == -1 ){
            users_by_zendesk.push(this.state.campaigns[index].user)
          }
          dates_by_zendesk.push(new Date(this.state.campaigns[index].created))
        }else if(this.state.campaigns[index].requestedBy == "WebService")
        {
          if(users_by_web.indexOf(this.state.campaigns[index].user) == -1 ){
            users_by_web.push(this.state.campaigns[index].user)
          }       
          dates_by_web.push(new Date(this.state.campaigns[index].created))
  
        }else if(this.state.campaigns[index].requestedBy == "Risk")
        {
              
          dates_by_web.push(new Date(this.state.campaigns[index].created))
  
        }else if(this.state.campaigns[index].requestedBy == "Cloud")
        {

          if(users_by_cloud.indexOf(this.state.campaigns[index].user) == -1 ){
            users_by_cloud.push(this.state.campaigns[index].user)
          }  
              
          dates_by_cloud.push(new Date(this.state.campaigns[index].created))
  
        }
      }
    }else{
      for (let index = 0; index < this.state.campaigns.length; index++) {
        if(users_by_platform.indexOf(this.state.campaigns[index].user) == -1 ){
          users_by_platform.push(this.state.campaigns[index].user)
        }       
        dates.push(new Date(this.state.campaigns[index].created))
      }
    }

    let filtered_campaigns = []
    if( platform == "WebService" ){
      filtered_campaigns = this.state.webservice_campaigns;
      users_by_platform = users_by_web
      dates = dates_by_web
    }else if(platform == "Zendesk"){
      filtered_campaigns = this.state.zendesk_campaigns;
      users_by_platform = users_by_zendesk
      dates = dates_by_zendesk
    }else if(platform == "Risk")
    {
      filtered_campaigns = this.state.risk_campaigns;
      // users_by_platform = users_by_web
      dates = dates_by_web
    }else if( platform == "Cloud" )
    {
      filtered_campaigns = this.state.cloud_campaigns;
      users_by_platform = users_by_cloud;
      dates = dates_by_cloud;

    }
    else{
      filtered_campaigns = this.state.campaigns;      
    }

    
    let new_filter = []
    if(user_filter!=""){
      for (let index = 0; index < filtered_campaigns.length; index++) {
        if(filtered_campaigns[index].user==user_filter) new_filter.push(filtered_campaigns[index])
      }
    }
    if(name_filter!=""){
      if(user_filter == "") new_filter = filtered_campaigns
      new_filter = this.searchFilter(name_filter, new_filter)
    }
    if(date!=""){
      if(user_filter=="" && user_filter=="") new_filter = filtered_campaigns
      let date_start = new Date(date[0])
      let date_end = new Date(date[1])
      // date.setDate(date.getDate()+1)
      let filtered_date = []
      for (let index = 0; index < new_filter.length; index++) {
        let i_date = new Date(new_filter[index].created)
        if(i_date >= date_start && i_date <= date_end) filtered_date.push(new_filter[index])
      }
      new_filter = filtered_date
    }

    if( platform!="" ){
      if(user_filter=="" && name_filter=="" && date == ""  ) new_filter = filtered_campaigns
      let filtered_requestedBy = [];
      

      if(platform == "All"){
        for(let index=0; index < new_filter.length; index++){
          filtered_requestedBy.push(new_filter[index])
        }
      }else{
        for (let index = 0; index < new_filter.length; index++) {
          if(new_filter[index].requestedBy=="WebService") {
            filtered_requestedBy.push(new_filter[index])
          }else if( new_filter[index].requestedBy=="Zendesk"){
            filtered_requestedBy.push(new_filter[index])
          }else if( new_filter[index].requestedBy=="Risk"){
            filtered_requestedBy.push(new_filter[index])
          }else if( new_filter[index].requestedBy=="Cloud"){
            filtered_requestedBy.push(new_filter[index])
            // console.log(filtered_requestedBy)
            console.log("Cloud")
          }
        }
      }

      new_filter = filtered_requestedBy;
    }
    
    this.setState({filtered_campaigns:new_filter, users: users_by_platform, dates})
    this.paginate(new_filter)
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    
    let name_filter = e.target.name == "name_filter" ? e.target.value.toUpperCase() : this.state.name_filter
    let user_filter = e.target.name == "user_filter" ? e.target.value : this.state.user_filter
    let date_filter_range = e.target.name == "date_range" ? e.target.value : this.state.date_range
    let platform = e.target.name == "platform_filter" ? e.target.value : this.state.platform_filter

    this.calculate_filter(name_filter.toUpperCase(), user_filter, date_filter_range, platform)
  }

  onDateChange = e => {
    this.setState({date_range:e, date_filter_start:e[0], date_filter_end:e[1]})
    this.calculate_filter(this.state.name_filter.toUpperCase(), this.state.user_filter, e, this.state.platform_filter)
  }

  searchFilter = (user_name, campaigns) => {
    var search_value = user_name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    var filtered_employees = [];
    let name="";
    for (var i = 0; i < campaigns.length; i++) {
      name = (campaigns[i]._id.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      if (name.includes(search_value)) {
        filtered_employees.push(campaigns[i]);
      }
    }
    return filtered_employees
  }

  dateFormat = (date) => {
    if(date === "" || date === undefined){
      return "";
    }else{
      return new Date(date).toISOString();
    }
  }

  getReferenceData = () => {
    if( this.state.platform_filter == "All" ){
      return "All";
    }else{
      return "WebService";
    }
  }

  componentDidMount = () => {
    let user_id = ''
    let origin_phone_number = JSON.parse(localStorage.getItem("origin_phones"));
    let string_phone_array = []
    origin_phone_number.forEach(element => {
      string_phone_array.push(element.phone);
    });

    this.setState({phone_array:string_phone_array})

    if(this.props.auth.isAuthenticated()) user_id = jwtDecode(localStorage.getItem("id_token")).sub.replace('auth0|','')
    axios({
      method: 'GET',
      url: `${API_URL}/clip/campaigns/outbound?origin_phone=${string_phone_array.join("-")}`,
      headers: {"X-Api-Key": ACCESS_TOKEN}
    })
    .then( (res) => {
      console.log("string= ",string_phone_array.join("-"))
      let dates = []
      let users = []
      let web_service = []
      let form_zendesk = []
      let risk = []
      let cloud = []
      let valid_campaigns = []
      let c = res.data.response
      console.log("c= ",c)

      for (let index = 0; index < c.length; index++) {
        console.log(c[index].requestedBy)
        if(c[index].requestedBy === "WebService" ){
          web_service.push(c[index])
          valid_campaigns.push(c[index])
        }else if( c[index].requestedBy === "Zendesk" ){
          form_zendesk.push(c[index])
          valid_campaigns.push(c[index])
        }else if( c[index].requestedBy === "Risk" ){
          risk.push(c[index])
          valid_campaigns.push(c[index])
        }else if( c[index].requestedBy === "Cloud" ){
          cloud.push(c[index])
          valid_campaigns.push(c[index])
        }
        
      }

      for (let index = 0; index < web_service.length; index++) {
        if(users.indexOf(web_service[index].user) == -1 ){
          users.push(web_service[index].user)
        }       
        dates.push(new Date(web_service[index].created))
      }
      this.setState({
        campaigns:valid_campaigns, 
        filtered_campaigns: web_service,
        webservice_campaigns:web_service,
        zendesk_campaigns:form_zendesk, 
        cloud_campaigns: cloud,
        users, 
        filtered_user:web_service, 
        dates, 
        loading:false,
        risk_campaigns: risk
      })
      this.paginate(web_service)
    }).catch(error => {
      this.setState({loading:false})
      console.error('Error found in:', (error));
    });   
  }
  render() {
    //set the max day that can be selected
    const datemin= new Date();
    const minDate=datemin.setDate(datemin.getDate()-31) ;
    return (
      <div className="container-fluid px-0 d-flex">
        <SideNav {...this.props}
          className="d-none d-md-flex"
        />
        <div className="container-fluid px-0">
          <div className="row mx-0 d-none d-md-block">
            <div className="col-12 px-0">
              <NavBar/>
            </div>
          </div>
          <div className="row mx-0 d-md-none">
            <div className="col-12 px-0">
              <ResponsiveNavBar/>
            </div>
          </div>
          
          <div className="row mx-0 min-h-page">
            <div className="col-12 px-0">

              {/* When loading content page */}
              {this.state.loading == true &&
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <EmptyPage
                      {...this.props}
                        page="loading"
                        />
                    </div>
                  </div>
                </div> 
              }
              {/* When loading content page

              {/* When empty page */}
              {this.state.campaigns.length == 0 && this.state.loading == false &&
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <EmptyPage
                      {...this.props}
                        page="home"
                        />
                    </div>
                  </div>
                </div> 
              }
              {/* When empty page

              {/* Content of te page */}
              {this.state.campaigns.length > 0 &&
              <div className="container mt-50">
                <div className="row">
                  <div className="col-12">
                    <p className="h1-b">Bienvenido</p>
                    <p className="h3-r">Aquí puedes ver la información de las campañas y su estatus.</p>
                  </div>
                </div>

                {/* Searcher */}
                <div className="row">
                  <div className="col-12">
                    <div className="input-group">
                      <input type="search" name="name_filter"placeholder="Buscar por nombre" onChange={this.onChange} value={this.state.name_filter}/>
                      <span class="input-icon material-icons-outlined md-24">search</span>
                      <label className="inputname">Buscar</label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 mt-10">
                    <div className="input-group">
                      <select name="user_filter" id="" placeholder="Selecciona un teléfono" onChange={this.onChange} value={this.state.user_filter}>
                        <option value="" >Todas</option>
                        {this.state.users.map((element, index)=>{
                          return(

                            <option value={element} key={index}>{element}</option>
                          )
                        })}
                      </select>
                      <label className="inputname">Buscar por usuario</label>
                    </div>
                  </div> 
                  <div className="col-12 col-md-6 col-lg-4 mt-10">
                    <div className="input-group">
                      <select name="platform_filter" id="" placeholder="Selecciona un método de envío" onChange={this.onChange} value={this.state.platform_filter}>
                        {options.map(item => {
                            return (<option key={item.value} value={item.value}>{item.label}</option>);
                        })}
                      </select>
                      <label className="inputname">Buscar por método de envío</label>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-3 mt-10">
                    {/* <div className="input-group">
                      <input type="date" name='date_filter' value={this.state.date_filter} onChange={this.onChange} max={new Date().toISOString().split('T')[0]}/>
                    </div> */}
                    <label className="inputname">Buscar por fecha</label>
                    <div>
                      <div className="date-picker-wrapper">
                            <DatePicker 
                              // selected={this.state.date_filter_start} 
                              onChange={this.onDateChange}
                              className="red-border"
                              minDate={minDate}
                              maxDate={new Date()}
                              name={'date'}
                              startDate={this.state.date_filter_start}
                              endDate={this.state.date_filter_end}
                              selectsRange={true}
                              />
                            <span class="material-icons-outlined">calendar_today</span>
                          </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-1 col-lg-1 d-flex mt-md-30">
                    <a className="mt-auto w-100" data-tip="Eliminar búsqueda">
                      <button className="button -danger d-flex justify-content-center align-items-center w-100" onClick={this.clear}><span class="material-icons-outlined md-24">backspace</span></button>
                    </a>
                    <ReactTooltip place="bottom" type="dark" effect="float"/>
                  </div>
                  
                  {/* <div className="col-12 col-md-12 col-lg-3">
                    <input
                          name="zendesk_data"
                          type="checkbox"
                          checked={this.state.zendesk_data}
                          onChange = { this.onChange } /> {!this.state.zendesk_data ? "Mostrar" : "Esconder"} Registros Zendesk 
                  </div> */}
                </div>
                {/* Searcher */}
                

                
                { ( localStorage.getItem('role') === "1" || localStorage.getItem('role') === "2" || localStorage.getItem('role') === "4" ) && 
                  <div className="row mt-20">
                    <div className="col-12 d-flex">
                    <a className="mt-auto w-100" data-tip="Generar documento">
                      <button className="button -primary ml-auto" onClick={() => window.open(API_URL + '/Clip/Download/Documents' +`?campaign_regex=${this.state.name_filter}&date_start=${this.dateFormat(this.state.date_range[0])}&date_end=${this.dateFormat(this.state.date_range[1])}&user=${this.state.user_filter}&platform=${this.state.platform_filter}&origin_phones=`, "_blank" )} ><i class="fas fa-download"></i> Descargar Campañas</button>
                    </a>
                    <ReactTooltip place="bottom" type="dark" effect="float"/>
                    </div>
                  </div>
                }
                

                <div className="row mt-20">
                <div className="col-12">
                <div className={`content mb-10 bg-secondary p-3`}>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <p className="text name h3-r mb-0 c-white">Nombre:</p>
                      
                    </div>
                    <div className="col-12 col-md-2">
                      <p className="text name h3-r mb-0 c-white">Mensajes:</p>
                    </div>
                    <div className="col-12 col-md-2">
                      <p className="text name h3-r mb-0 c-white">Estado:</p>
                    </div>
                    <div className="col-12 col-md-4">
                      <p className="text name h3-r mb-0 c-white">Usuario:</p>
                    </div>
                  </div>
                </div>

                      </div>
                {this.state.paginated_campaigns.length > 0 && this.state.paginated_campaigns[this.state.current_page].map((element)=>{
                  // console.log(element);
                  return(
                    <div className="col-12">
                          <CampaignBar
                            link={`/campaign-detail/${element._id}`}
                            campaignName={element._id}
                            date={element.created}
                            scheduled={element.scheduled}
                            scope={element.amount}
                            campaignState={element.campaign_status}
                            percentage={element.percentage}
                            creator={element.user}
                            className="mb-10"
                          />
                      </div>
                  )
                })}
                </div>

                <div className="row mt-20">
                  <div className="col-12">
                    <Paginator
                      page_size={this.state.page_size}
                      size={this.state.paginated_campaigns.length}
                      current_page={this.state.current_page}
                      setPage={this.setpage}
                    />
                  </div>
                </div>
              </div>
              }
              {/* Content of te page */}
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 px-0">
              <Footer
                className="mt-100"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
